import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Select } from './select';
import { Playground, Props } from 'docz';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "select"
    }}>{`Select`}</h1>
    <p>{`The select component allows provides you with an international friendly way to switch values given by an input.
Selects are essentially `}<em parentName="p">{`compile time`}</em>{` versions of a `}<inlineCode parentName="p">{`switch`}</inlineCode>{` statement or object lookup. The problems that those
run time solutions can execute arbitrary code and are very different to analyze statically. The difference that
Select provides is an easy to use run time and compile time interface for Stele to use.`}</p>
    <h3 {...{
      "id": "on-matches"
    }}>{`On matches`}</h3>
    <p>{`Matches should usually be something with multiple values.
A boolean is really hard to support from a translation perspective.
It is usually much easier to use two messages instead.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import * as React from 'react'
import { Select } from '@patreon/stele'

function MyComponent(props) {
    return (
        <Text intl>
            But babe,
            <Select
                value={props.character}
                matches={{ ben: 'calzone', andy: 'pizza' }}
            />
        </Text>
    )
}
`}</code></pre>
    <h2 {...{
      "id": "example-message"
    }}>{`Example message`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`But babe, {character, plural,
    ben {calzone}
    andy {pizza}
}
`}</code></pre>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Select} mdxType="Props" />
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <p>{`The value prop can be either a number or a string. The value must be a key inside the matches object.`}</p>
    <Playground __position={1} __code={'() => {\n  const [checked, setChecked] = React.useState(false)\n  const yesOrNo = checked ? \'yes\' : \'no\'\n  return (\n    <>\n      <label>\n        Does Kel love orange soda?\n        <input\n          type=\"checkbox\"\n          checked={checked}\n          onChange={_ => setChecked(!checked)}\n        />\n      </label>\n      <p>\n        Is it true?\n        <Select\n          value={yesOrNo}\n          matches={{ yes: \'I do, I do, I doooo\', no: \'No :(\' }}\n        />\n      </p>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Select,
      Playground,
      Props
    }} mdxType="Playground">
    {() => {
        const [checked, setChecked] = React.useState(false);
        const yesOrNo = checked ? 'yes' : 'no';
        return <>
                <label>
                    Does Kel love orange soda?
                    <input type="checkbox" checked={checked} onChange={_ => setChecked(!checked)} />
                </label>
                <p>
                    Is it true?
                    <Select value={yesOrNo} matches={{
              yes: 'I do, I do, I doooo',
              no: 'No :('
            }} mdxType="Select" />
                </p>
            </>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      